import * as React from "react"
import { graphql } from "gatsby";
import { buildSections } from '../services/sections';
import { useEffect } from "react";
import {Seo} from "../components/seo/seo";

// markup
const NotFoundPage = ({ data}) => {
  const breadcrumbPath = [{title:'404'}];
  const sections = data.cms.pages.data[0].attributes.sections;
  const pageData = data.cms.pages.data[0].attributes;

  return (
    <div className="container-fluid">
      <Seo pageContext={{}} pageData={pageData} />
      {
        buildSections(sections, null, null, null, breadcrumbPath, -1)
      }
    </div>
  )
}

export default NotFoundPage;

export const query = graphql`
  query notFoundPageQuery {
    cms {
      pages(filters: { name: { eq: "storelocator/404" }}) {
        data {
          attributes {
            sections {
              __typename
              ... on CMS_ComponentStoreLocatorSectionTexteImage {
                id
                bouton {
                  titre
                  style
                  url
                  action
                }
                slide {
                  id
                  tag
                  texte
                  titre
                }
                image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on CMS_ComponentStoreLocatorSectionCarteAgences {
                id
              }
            }
            seoMetaDescription
            seoMetaTitle
          }
        }
      }
    }
  }
`;
